// React Required
import React from "react";
import { createRoot } from "react-dom/client";
import PageScrollTop from "./PageScrollTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUsPage from "./script/ContactUsPage";
import AppTest from "./AppTest";
import AboutUsPage from "./AboutUsPage";
import PointFiveDetailPage from "./script/PointFiveDetailPage";
import BL1NKDetailPage from "./BL1NKDetailPage";
import BaaridgeDetailPage from "./BaaridgeDetailPage";
import KeepOnTruckingDetailPage from "./script/KeepOnTruckingDetailPage";
import Stack8DetailPage from "./script/Stack8DetailPage";
import PrivacyPolicy from "./script/PrivacyPolicy";
import RedLightCamDetailPage from "../src/redlightcam"

const container = document.getElementById("root");
const root = createRoot(container); 
root.render(
  <BrowserRouter basename={"/"}>
    <PageScrollTop>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
        <Route path="/portfolio/redlightcam" element={<RedLightCamDetailPage/>} />
      <Route path="/portfolio/stack-8" element={<Stack8DetailPage/>} />
      <Route path="/portfolio/keep-on-trucking" element={<KeepOnTruckingDetailPage/>} />
      <Route path="/portfolio/baaridge" element={<BaaridgeDetailPage/>} />
      <Route path="/portfolio/bl1nk" element={<BL1NKDetailPage/>} />
      {/* <Route path="/portfolio/point-five" element={<PointFiveDetailPage/>} /> */}
        <Route path="#about-us" element={<AboutUsPage/>} />
        <Route path="#contact-us" element={<ContactUsPage />} />
        <Route exact path={`${process.env.PUBLIC_URL}`} element={<AppTest />} />
      </Routes>
    </PageScrollTop>
  </BrowserRouter>
);
