import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
import {
    homeClick,
    underlineMenuAbout,
    underlineMenuContact,
    underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
const iconImage = require("./test-img/portfolio-1.JPEG");
const appleImage = require("./test-img/apple.png");
const screenShot1 = require("./test-img/k-1.png");
const screenShot2 = require("./test-img/k-2.png");
const screenShot3 = require("./test-img/k-3.png");

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const KeepOnTruckingDetailPage = () => {
    useTitle("KEEP ON TRUCKING - THE RISE COLLECTION")

    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
            <div className="flex-box-header header" id="detail">
                <div className="headerText">
                    <a href="/">
                        <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
                    </a>
                </div>
                <div className="menuItems">
                    <Link to="/" onClick={homeClick}>
                        <p id="homeMenuText">HOME</p>
                    </Link>
                    <Link to="/#about-us" onClick={underlineMenuAbout}>
                        <p id="aboutUsMenuText">ABOUT US</p>
                    </Link>
                    <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
                        <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
                    </Link>
                    <Link to="/#contact-us" onClick={underlineMenuContact}>
                        <p id="mediaMenuText">CONTACT US</p>
                    </Link>
                </div>
            </div>


            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                        <h2 style={{ fontSize: "25px" }}>KEEP ON TRUCKING</h2>
                        <p style={{ fontSize: '18px' }}>THE RISE COLLECTION</p>

                        <div className='tag-container'>
                        <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.5px', width: '125px' }}><a>MOBILE GAME</a></div>

                            <div className='left-tag' style={{ background: 'blue', width: '35px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.5px' }}><a>IOS</a></div>

                        </div>

                    </div>
                </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Mobile Game</h2>
                    <p style={{ fontSize: '18px' }}>
                        Keep on trucking and don't stop!!!


                        Tap your finger on either the right or left of the screen to guide the truck.. hit all normal cars but avoid the cops! How far can you go?
                    </p>
                    <hr />

                </div>
                <h2>Media</h2>

                <div className="screenshot-container">

                    <img src={screenShot1} alt='KEEPONTRUCKING_SCREENSHOT_1' />
                    <img src={screenShot2} alt='KEEPONTRUCKING_SCREENSHOT_2' />
                    <img src={screenShot3} alt='KEEPONTRUCKING_SCREENSHOT_3' />

                </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: December 2, 2016</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red" }}>THE RISE COLLECTION</a></li>                    </ul>
                </div>
                <hr></hr>
                <h2>Download</h2>

                <div className="game-description">

                    <a href='https://apps.apple.com/mm/app/keep-on-trucking/id1176379021'><img src={appleImage} style={{ width: '175px', marginLeft: '-10px' }} alt='KEEPONTRUCKING_DOWNLOAD_IOS' /></a>

                </div>
            </div>
            <br></br>
            <footer className="footer" id='detail-footer'>
                <div className="footer-content">

                    <div className="footer-social">
                        <a href="https://www.instagram.com/rwtrcsc/">
                            <FaInstagramSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>
                        <a href="https://twitter.com/rwtrcsc">
                            <FaTwitterSquare style={{
                                color: "white",
                                height: "35px",
                                width: "25px",
                                marginRight: "15px",
                            }}></FaTwitterSquare>

                        </a>
                        <a href="https://www.facebook.com/rwtrcsc/">
                            <FaFacebookSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>
                        <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q">
                            <FaYoutubeSquare
                                style={{
                                    color: "white",
                                    height: "35px",
                                    width: "25px",
                                    marginRight: "15px",
                                }}
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/therisecollection">
                            <FaLinkedin
                                style={{ color: "white", height: "35px", width: "25px" }}
                            />
                        </a>
                    </div>
                    <br></br>
                    <a href="/privacy-policy" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>PRIVACY POLICY</a>

                </div>
            </footer>
        </div>
    )
}

export default KeepOnTruckingDetailPage
